.bso-banner {
  background: var(--bannerBackgroundColor);
  color: var(--bannerTextColor);
  padding: 50px 0;

  .sup-title {
    @include font-size(20, 24);
  }

  .main-title {
    @include font-size(32, 40);
    color: inherit;
  }

  .sub-title {
    @include font-size(16, 24);
    color: inherit;
  }

  .sub-sub-title {
    @include font-size(14, 20);
    color: inherit;
    font-weight: 500;
  }

  .bso-select-navigation {
    border-radius: 8px;
    bottom: -130px;
    color: white;
    top: -25px;
    width: 100%;

    &.dark-background {
      background: var(--blue-dark-125);
    }

    &.soft-background {
      background: var(--blue-soft-125);
    }

    .fr-select--valid {
      box-shadow: none;
    }
  }

  &.sticky {
    padding: 0;
    position: sticky;
    top: 0;

    .sub-title,
    .sub-sub-title,
    .icons,
    .chip {
      display: none;
    }

    .sup-title {
      @include font-size(12, 16);
    }

    .main-title {
      @include font-size(14, 16);
    }

    .bso-select-navigation {
      bottom: 0;
      border-radius: 0;
      position: relative;
      top: 0;

      .title {
        display: none;
      }
    }

    .home {
      box-shadow: none;
    }
  }

  button {
    border-bottom: 2px solid var(--green-soft-25);
    border-radius: 0;
    color: var(--blue-dark-125);
    padding-left: 5px;
    padding-right: 5px;

    &.active {
      border-color: var(--blue-dark-125);
    }
  }
}

.bso-link {
  @include font-size(14, 12);
  background: #fff;
  color: var(--blue-dark-125);
  padding: 10px;
}

@media screen and(min-width: 576px) {
  .banner {
    padding: 30px 0;
  }
}
