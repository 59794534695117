:root {
  --blue-soft-175: #26283f;
  --blue-soft-150: #334476;
  --blue-soft-125: #5266a1;
  --blue-soft-100: #5770be;
  --blue-soft-75: #abb8de;
  --blue-soft-50: #d5dbef;
  --blue-soft-25: #eef1f8;
  --blue-dark-500: #484d7a;
  --blue-dark-125: #3d4167;
  --blue-dark-100: #f6f6f8;
  --blue-dark-75: #a4a6bc;
  --blue-dark-50: #d1d3de;
  --blue-dark-25: #ededf2;
  --g-800: #1e1e1e;
  --g-750: #2a2a2a;
  --g-700: #383838;
  --g-600: #6a6a6a;
  --g-500: #9c9c9c;
  --g-400: #cecece;
  --g-300: #e7e7e7;
  --g-200: #f0f0f0;
  --beige: #f9f8f6;
  --orange-soft-175: #8f4939;
  --orange-soft-150: #b8482e;
  --orange-soft-125: #cb634b;
  --orange-soft-100: #ff6f4c;
  --orange-soft-75: #ffb7a5;
  --orange-soft-50: #ffdbd2;
  --orange-soft-25: #fff1ed;
  --orange-medium-150: #8d5b2d;
  --orange-medium-125: #d38742;
  --orange-medium-100: #ff9940;
  --orange-medium-75: #ffcc9f;
  --orange-medium-50: #ffe5cf;
  --orange-medium-25: #fff5ec;
  --orange-dark-125: #d0805b;
  --orange-dark-100: #e18863;
  --orange-dark-75: #f0c5b1;
  --orange-dark-50: #f8e2d8;
  --orange-dark-25: #fcf3ef;
  --yellow-medium-175: #6d6414;
  --yellow-medium-150: #bead1b;
  --yellow-medium-125: #ead737;
  --yellow-medium-100: #ffe800;
  --yellow-medium-75: #fff480;
  --yellow-medium-50: #fff9bf;
  --yellow-medium-25: #fffde5;
  --yellow-dark-125: #e3bd41;
  --yellow-dark-100: #fdcf41;
  --yellow-dark-75: #fff480;
  --yellow-dark-50: #fff9bf;
  --yellow-dark-25: #fffde5;
  --green-light-125: #88a34a;
  --green-light-100: #91ae4f;
  --green-light-75: #c8d7a7;
  --green-light-50: #e3ebd3;
  --green-light-25: #f4f7ed;
  --green-soft-125: #22967e;
  --green-soft-100: #00ac8c;
  --green-soft-75: #80d6c6;
  --green-soft-50: #bfeae2;
  --green-soft-25: #e5f7f4;
  --green-medium-150: #116741;
  --green-medium-125: #19905b;
  --green-medium-100: #169b62;
  --green-medium-75: #8bcdb1;
  --green-medium-50: #c5e6d8;
  --green-medium-25: #e8f5ef;
  --green-dark-125: #40605b;
  --green-dark-100: #466964;
  --green-dark-75: #a3b4b2;
  --green-dark-50: #d1dad8;
  --green-dark-25: #edf0f0;
  --green-warm-175: #72683e;
  --green-warm-150: #877e59;
  --green-warm-125: #958b62;
  --green-warm-100: #b6a24f;
  --green-warm-75: #cac5b1;
  --green-warm-50: #e5e2d8;
  --green-warm-25: #f4f3ef;
  --red-medium-175: #800c0c;
  --red-medium-150: #9c1515;
  --red-medium-125: #b81f1f;
  --red-medium-100: #da3636;
  --red-medium-75: #e37171;
  --red-medium-50: #edb5b5;
  --red-medium-25: #f3dada;
  --pink-light-125: #ddb094;
  --pink-light-100: #ffc29e;
  --pink-light-75: #ffe0cf;
  --pink-light-50: #fff0e7;
  --pink-light-25: #fff9f5;
  --pink-soft-175: #58231f;
  --pink-soft-150: #b1635c;
  --pink-soft-125: #d07c75;
  --pink-soft-100: #ff8d7e;
  --pink-soft-75: #ffc6bf;
  --pink-soft-50: #ffe2df;
  --pink-soft-25: #fff4f2;
  --pink-dark-125: #c0806f;
  --pink-dark-100: #d08a77;
  --pink-dark-75: #e7c4bb;
  --pink-dark-50: #f3e2dd;
  --pink-dark-25: #faf3f1;
  --purple-125: #714753;
  --purple-100: #7d4e5b;
  --purple-75: #bea6ad;
  --purple-50: #ded3d6;
  --purple-25: #f8f6f7;
  --purple-medium-175: #3b1d49;
  --purple-medium-150: #5c356f;
  --purple-medium-125: #825c94;
  --purple-medium-100: #a677bc;
  --purple-medium-75: #ce9de5;
  --purple-medium-50: #eccdfb;
  --purple-medium-25: #f6ebfc;
  --brown-125: #956052;
  --brown-100: #a26859;
  --brown-175: #d0b4ac;
  --brown-50: #e8dad6;
  --brown-25: #f6f0ee;
  --red-marianne-700: #a9000b;
  --red-marianne-600: #ca000d;
  --red-marianne-500: #e1000f;
  --red-marianne-400: #f07087;
  --red-marianne-300: #f7bfc3;
  --red-marianne-200: #fce5e7;
  --red-marianne-100: #fdf2f3;
  --blue-france-700: #00006d;
  --blue-france-600: #000074;
  --blue-france-500: #000091;
  --blue-france-400: #7f7fc8;
  --blue-france-300: #9a9aff;
  --blue-france-200: #ececff;
  --blue-france-100: #f5f5ff;

  /**
    Surcharge BSO
  */
  --acces-ouvert: #62b93a;
  --affiliations-etablissements-50: #cdc5e4;
  --affiliations-etablissements-75: #a798d1;
  --affiliations-etablissements-100: #846dc5;
  --affiliations-etablissements-125: #5b4697;
  --affiliations-etablissements-150: #351c7d;
  --apres: #cf7728;
  --black: #000;
  --diamond: #5cdce5;
  --duree: #9c807e;
  --hybrid: #725d3e;
  --lead-sponsor-highlight: #ef47ca;
  --lead-sponsor-privee: #5c356f;
  --lead-sponsor-public: #c26eb0;
  --negatif: #a20404;
  --ouvrir-la-science-blue: #5b29c8;
  --ouvrir-la-science-green: #5cf1ce;
  --ouvrir-la-science-yellow: #f9ee7a;
  --patient-25: #fbe9f4;
  --patient-50: #fbdff0;
  --patient-75: #f7b4dc;
  --patient-100: #f279c2;
  --patient-125: #a83a79;
  --publication-25: #f5d9e3;
  --publication-50: #e8b6c8;
  --publication-75: #d487a2;
  --publication-100: #d06088;
  --resultat-25: #fbfceb;
  --resultat-50: #f1f2c2;
  --resultat-75: #e0e287;
  --resultat-100: #cbcf33;
  --resultat-et-publication: #e49a43;
  --resultat-ou-publication: #f0cda4;
  --theseshal: #01c7bb;
  --thesesfr: #3fc5fc;
  --white: #fff;
}
