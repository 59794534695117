.bso-link-card {
  &.fr-card__content {
    padding: 20px;
  }

  .fr-card__desc {
    margin-bottom: 0;
  }

  a {
    @include font-size(12, 24);
    box-shadow: none;
  }

  .icon {
    min-height: 80px;
  }
}
