.bso-glossaire {
  a {
    text-decoration: underline !important;
  }
}

.bso-glossary {
  transition: right 0.6s ease-in-out;
  background-color: var(--blue-dark-125);
  color: #fff;
  width: 100vw;
  position: fixed;
  top: 0;
  right: -100vw;
  height: 100%;

  &.openPanel {
    transition: all 0.2s ease-out;
    box-shadow: 14px 0 25px rgb(61, 65, 103),
      17px 0 10px rgb(82, 102, 161),
      -2px 0 30px rgb(61, 65, 103);
    right: 0;
  }

  .close {
    color: #fff;
    background: none;
  }

  .wrapper-title {
    padding-bottom: 10px;
    border-bottom: 2px solid var(--blue-soft-100);
  }

  .content {
    color: #fff;
    overflow-y: scroll;
    max-height: calc(100vh - 120px);
    padding: 0 20px;

    p::first-letter {
      text-transform: uppercase;
    }

    a {
      text-decoration: underline !important;
    }

    .glossary-item {
      border-bottom: 2px solid var(--blue-soft-125);

      &.active p:first-child {
        color: var(--yellow-medium-100);
      }
    }
  }

  .fr-btn {
    position: relative;
    width: 120px;
    left: -136px;
    top: 55px;
    transition: top 0.4s ease-in-out;

    .banner-sticked & {
      transition: top 0.4s ease-in-out;
      top: 10px;
    }
  }

  .to-glossary-page {
    @include font-size(12, 12);
    box-shadow: none;
    padding: 6px 16px;
    background-color: var(--blue-soft-100);

    span {
      vertical-align: middle;
    }
  }
}

.glossary-entry {
  position: relative;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 2px solid #fff;
  border-color: inherit;
  margin: 0 26px 0 4px;

  &::after {
    @include font-size(14, 19);
    content: 'i';
    font-family: 'dsfr-icons', serif;
    top: 4px;
    position: absolute;
    background-color: var(--blue-dark-125);
    border-radius: 30px;
    color: white;
    height: 18px;
    width: 18px;
    text-align: center;
    right: -22px;
  }

  &.active {
    background-color: var(--yellow-medium-100);
  }
}

@media screen and(min-width: 992px) {
  .bso-glossary {
    width: 40vw;
    right: -40vw;

    .fr-btn {
      left: -144px;
      top: 52vh;
    }
  }
}

@media screen and(min-width: 768px) {
  .banner-sticked {
    .bso-glossary {
      .fr-btn {
        top: 10px;
      }
    }
  }
}
