/**
 * DSFR Fixes
 **/

html {
  h1,
  h2,
  h3 {
    color: var(--blue-dark-125);
  }

  h2,
  h3 {
    margin: 0 0 1rem;
  }

  .fr-enlarge-link:hover {
    background-color: inherit;
  }

  [href] {
    background-image: none;
  }

  a[href].bso-link:hover {
    background-color: white;
  }

  [target=_blank]::after {
    content: none !important;
  }

  a[aria-disabled="true"]:not([href]) {
    color: #fff;
    cursor: pointer;
  }

  .fr-hr,
  hr {
    padding: 1px 0;
  }

  .tab-links hr {
    margin-bottom: 7px;
    margin-top: 7px;
  }

  .fr-link {
    display: inline-flex;
  }

  .fr-links-group .fr-link {
    margin-left: 0;
    margin-right: 0;
  }

  .fr-link--close {
    display: flex;
  }

  .fr-footer {
    border-top: 2px solid var(--blue-france-500);
  }

  .fr-header__menu-links .fr-link {
    box-shadow: none;
  }

  .fr-card--sm.fr-enlarge-link:not(.fr-card--no-icon) .fr-card__content {
    padding: 1.5rem;
  }

  .fr-card--sm .fr-card__desc {
    margin: 0 0 1rem 0;
  }

  .no-arrow-link [class^=fr-link][target=_blank]::after,
  .no-arrow-link [target=_blank]::after {
    display: none;
  }
}
