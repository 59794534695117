.italic {
  font-style: italic;
}

.text-green {
  color: var(--green-medium-100);
}

.text-red {
  color: var(--red-medium-100);
}
