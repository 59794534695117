@for $i from 1 through $icon-bsso-max {
  .icon-bsso-#{$i} {
    .color-2 {
      stroke: var(--icon-bsso-#{$i}-color-2);
    }

    .color-1 {
      stroke: var(--icon-bsso-#{$i}-color-1);
    }
  }
}
