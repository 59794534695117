.bso-datacard {
  color: var(--blue-dark-125);

  .pink {
    background-color: var(--publication-25);
  }

  .brown {
    background-color: var(--brown-50);
  }

  .aqua {
    background-color: #ddf7f9;
  }

  .yellow {
    background-color: var(--yellow-medium-50);
  }

  .blue {
    background-color: var(--blue-soft-50);
  }

  .green {
    background-color: var(--green-soft-50);
  }

  .gauge {
    max-height: 200px;

    svg {
      path {
        transform: rotate(-30.999999999999986deg);
        transform-origin: 100px 100px;

        &.stroke {
          stroke: var(--blue-dark-125);
        }
      }

      text {
        @include font-size(40, 40);
        fill: var(--blue-dark-125);
      }
    }
  }

  .fr-card__content {
    justify-content: center;
  }

  .sentence {
    @include font-size(14, 20);
  }

  .top-data {
    @include font-size(48, 40);
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 2px 2px #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 0 2px 0 #fff;
  }

  .btn-bottom {
    position: absolute;
    bottom: 20px;
    width: 160px;
    left: calc(50% - 80px);
  }
}
