.bso-betachip {
  @include font-size(12, 16);
  align-items: center;
  background: var(--yellow-medium-100);
  border-radius: 4px;
  color: var(--blue-soft-150);
  display: flex;
  font-weight: 600;
  height: 14px;
  justify-content: center;
  padding: 12px;
  text-align: center;
  width: 130px;
  z-index: 1;
}

.out-of-the-box {
  left: -50px;
  position: relative;
  top: -30px;
}
