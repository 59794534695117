/*
  Layout
 */
@import 'reset.scss';
@import 'mixins.scss';
@import 'colours.module.scss';
@import 'breakPoints.scss';
@import 'variables.scss';
@import 'fonts.scss';
@import 'commons.scss';
@import 'pages.scss';
@import 'fix-dsfr.scss';

/*
  Components
 */
@import '../components/BetaChip/style.scss';
@import '../components/Banner/style.scss';
@import '../components/Chip/style.scss';
@import '../components/DataCard/style.scss';
@import '../components/FlashCard/style.scss';
@import '../components/Glossary/style.scss';
@import '../components/GraphNavigation/style.scss';
@import '../components/Header/style.scss';
@import '../components/HomeSection/style.scss';
@import '../components/Icon/style.scss';
@import '../components/LinkCard/style.scss';
@import '../components/ScrollTop/style.scss';

/*
  Global
 */
html {
  font-size: 100%;
}
