:root {
  --xs: 320;
  --s: 376;
  --m: 576;
  --l: 736;
  --xl: 992;
  --xxl: 1280;
}

$icon-bsso-max: 37;
