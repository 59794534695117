.home-section {
  background: var(--blue-soft-50);
  padding-top: 50px;

  .button-link {
    padding: 16px 20px;
    color: #fff;
    background: var(--blue-soft-100);
    bottom: -16px;

    a {
      box-shadow: none;
    }
  }
}
