/**
 * Général
 */

html {
  scroll-behavior: smooth;

  .overlay {
    display: none;
    background: #1e1e1e;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
  }

  &.overlay-on {
    .overlay {
      display: block;
    }
  }

  .no-content-after::after {
    content: '';
  }
}

p a {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.cursor-default,
.cursor-default > a,
.cursor-default > a:not([href]) {
  cursor: default;
}

ul.style-disc > li {
  list-style: disc;
  margin-left: 15px;
}

.fr-display-xs.title-xxs {
  font-size: 2rem !important;
  margin-bottom: 0.5rem;
}

/**
 * Textes
 */
.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-upper {
  text-transform: uppercase;
}

/**
 * Block
 */
.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex,
div.d-inline-flex {
  display: inline-flex;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear {
  clear: both;
}

.code {
  background-color: lightgrey;
  border: 1px solid grey;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.8rem;
  padding: 8px;
  white-space: pre-wrap;
}

.studio {
  margin-left: -32px;
  overflow: visible;
}

/**
 * Position
 */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.z-100 {
  z-index: 100;
}

.z-200 {
  z-index: 200;
}

.z-3600 {
  z-index: 3600;
}

.z-4000 {
  z-index: 4000;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.none {
  display: none;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-100 {
  width: 100%;
}

.h-50 {
  height: 50%;
}

.h-100 {
  height: 100%;
}

/**
 * Colorimétrie
 */
.bg-blue-soft-25 {
  background: var(--blue-soft-25);
}

.bg-blue-soft-50 {
  background: var(--blue-soft-50);
}

.bg-blue-soft-100 {
  background: var(--blue-soft-100);
}

.bg-blue-soft-150 {
  background: var(--blue-soft-150);
}

.bg-green-light-50 {
  background-color: var(--green-light-50);
}

.bg-green-medium-25 {
  background: var(--green-medium-25);
}

.bg-green-soft-25 {
  background-color: var(--green-soft-25);
}

.bg-green-soft-100 {
  background-color: var(--green-soft-100);
}

.bg-orange-soft-100 {
  background: var(--orange-soft-100);
}

.bg-pink-light-50 {
  background-color: var(--pink-light-50);
}

.bg-publication-25 {
  background-color: var(--publication-25);
}

.bg-purple-25 {
  background-color: var(--purple-25);
}

.bg-purple-medium-25 {
  background-color: var(--purple-medium-25);
}

.bg-purple-medium-50 {
  background-color: var(--purple-medium-50);
}

.bg-purple-medium-100 {
  background-color: var(--purple-medium-100);
}

.bg-white {
  background-color: #fff;
}

.bg-yellow-medium-50 {
  background-color: var(--yellow-medium-50);
}

.bg-yellow-medium-100 {
  background-color: var(--yellow-medium-100);
}

.color-blue-dark-125 {
  color: var(--blue-dark-125);
}

.color-blue-soft-175 {
  color: var(--blue-soft-175);
}

.color-white {
  color: white;
}

.fr-btn {
  &.btn-blue {
    background-color: var(--blue-dark-125);
  }

  &.btn-transparent {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &.bg-blue-soft-100 {
    background: var(--blue-soft-100);
  }

  &:hover {
    background-color: var(--blue-dark-100);
  }
}

/**
 * Espacement
 */

.p-0 {
  padding: 0 !important;
}

.p-8 {
  padding: 8px;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-32 {
  padding-top: 32px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-56 {
  padding-bottom: 56px;
}

.px-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.px-7 {
  padding-left: 7px;
  padding-right: 7px;
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.px-28 {
  padding-left: 28px;
  padding-right: 28px;
}

.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-28 {
  padding-top: 28px;
  padding-bottom: 28px;
}

.py-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.m-0 {
  margin: 0;
}

.mx-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.mx-5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.my-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-n50 {
  margin-bottom: -50px;
}

.mb-n171 {
  margin-bottom: -171px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-68 {
  margin-left: 68px;
}

.ml-n16 {
  margin-left: -16px;
}

.ml-n25p {
  margin-left: -25%;
}

.mr-6 {
  margin-right: 6px;
}

.mr-26 {
  margin-right: 26px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-500 {
  margin-right: 500px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-90 {
  margin-top: 90px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-n16 {
  margin-top: -16px;
}

.mt-n50 {
  margin-top: -50px;
}

.mt-n100 {
  margin-top: -100px;
}

.m-auto {
  margin: auto !important;
}

.fr-col-mt-auto {
  margin-top: auto !important;
}

.fr-col-mb-auto {
  margin-bottom: auto !important;
}

/**
 * Mediaqueries
 */
@media screen and(min-width: 1280px) {
  .text-center-xxl {
    text-align: center;
  }

  .text-left-xxl {
    text-align: left;
  }

  .max-996 {
    max-width: 996px;
    margin: 0 auto;
  }
}

@media screen and(min-width: 992px) {
  .text-center-xl {
    text-align: center;
  }

  .text-left-xl {
    text-align: left;
  }

  .block-xl {
    display: block;
  }

  .px-xl-142 {
    padding-left: 142px;
    padding-right: 142px;
  }
}

@media screen and(min-width: 768px) {
  .text-center-l {
    text-align: center;
  }

  .text-left-l {
    text-align: left;
  }

  .mb-l-0 {
    margin-bottom: 0;
  }

  .mb-l-60 {
    margin-bottom: 60px;
  }

  .px-l-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-l-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
}

@media screen and(min-width: 576px) {
  .text-center-m {
    text-align: center;
  }

  .text-left-m {
    text-align: left;
  }

  .px-md-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
}

@media screen and(min-width: 376px) {
  .text-center-s {
    text-align: center;
  }

  .text-left-s {
    text-align: left;
  }
}

.external_link {
  text-decoration: underline;
  font-weight: 900;
}

.bold {
  font-weight: 900;
}

.underline {
  text-decoration: underline;
}

.beta-title {
  text-decoration: underline;
  background: var(--yellow-medium-100);
}

.my-button {
  background: var(--blue-soft-100);
  color: white;
  padding: 10px;
  text-align: center;
  margin: 10px;
}

.doiCol {
  background: var(--blue-soft-50);
}

.halStructCol {
  background: var(--green-soft-50);
}

.halCollCol {
  background: var(--green-soft-100);
}

.halIdCol {
  background: var(--green-light-50);
}

.nntEtabCol {
  background: var(--thesesfr);
}

.nntIdCol {
  background: var(--blue-france-300);
}
