.bso-header {
  .main-title {
    color: var(--blue-soft-100);
  }
}

@media screen and (min-width: 992px) {
  .bso-header {
    .main-title {
      max-width: 270px;
    }
  }
}
